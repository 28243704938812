<template>
<div>
  <img class="lajilaji" src="../../assets/img/2021/ens.jpg" alt="">

  <div class="w-1200">
    <div class="ens-text">
      <h3>Introduction</h3>

      <p>
        Gemdale Sports was established in 2010 and is the wholly-owned subsidiary of Gemdale. For a long time, Gemdale Sports has been committed to the commercialization of Chinese sports industry. After more than 10 years of development, Gemdale Sports shapes into four industrial groups: Competitive Performance Business, Player Training and Management Business , Sports Assets Management, Digital Sports Construction and Operation.
      </p>
      <p>
        Gemdale Sports has been deeply cultivated in the sports industry for 11 years.In terms of Competitive Performance Business, Gemdale Sports has formed a highly international and professional event matrix. As for Player Training and Management Business, with its own advantages on tennis, Gemdale Sports has formed a professional chain of tennis from training to brokerage. In the aspect of Sports Assets Management, Gemdale Sports has formed three business types including comprehensive stadiums operation, community parks sports program activation and operation and commercial sports stores operation. In terms of Digital Sports Construction and Operation, Gemdale Sports build a digital sports ecology through the Intelligent Platform by the mode of SaaS and O2O.
      </p>
      <p>
        Through the coordination of the four industrial clusters, Gemdale Sports has already become the 1st Chinese international sports enterprise covering the whole tennis industry chain. In the meantime, expanding new business and sports, continuously expand the territory of the event, improving players training and management system. More diversified sports service are provided to the society by Gemdale Sports. We are committed to build a sports industry chain with varied formats. Gemdale Sports will also cooperate with real estate which is the core business of Gemdale Group to build an ecosystem of sports and city, promoting the optimization and upgrading of urban industrial structure. To be the most valuable international sports enterprise in China.
      </p>
    </div>

    <div class="bottom-box">
      <div class="img-div">
        <img src="../../assets/img/2021/footer_logo.png" alt="">
      </div>
      <div class="text">
        <h3>Contact</h3>
        <p>
          Address：Xiangmihu Road No.4001，Futian Shenzhen Guangdong China
        </p>
        <p>
          Telephone：0755-83157766
        </p>
        <p>
          E-mail：pda@gemdale.com
        </p>
      </div>

    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "ens",
}
</script>

<style scoped lang="less">
.lajilaji{
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
h3{
  font-size: 18px;
  font-weight: bold;
}
.ens-text{
  font-size: 14px;
  margin: 100px auto;

  p{
    margin-top: 20px;
    text-align: justify;
    color: #000;
  }
}

.bottom-box{
  display: flex;
  margin-bottom: 60px;
  margin-top: 120px;
  .img-div{
    width: 88px;
    position: relative;
    img{
      position: absolute;
      top: 50%;
      margin-top: -14px;
      width: 88px;
      height: auto;
      float: left;
    }
  }

  .text{
    flex: 1;
    margin-left: 40px;
    color: #000;
    h3{
      margin-bottom: 8x;
    }
    p{
      margin-top: 4px;
    }
  }
}
</style>
